var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-container",
    {
      attrs: { icon: _vm.$route.meta.icon, title: _vm.$route.meta.title },
      scopedSlots: _vm._u([
        {
          key: "tools",
          fn: function() {
            return [
              _c("emc-excel-import-icon", {
                attrs: { param: "discipline_portfolios" },
                on: {
                  onCompleted: function($event) {
                    return _vm.getData()
                  }
                }
              }),
              _c("s-icon-report-download", {
                attrs: { "show-message": "", report: "107" }
              }),
              _c("emc-button-icon", {
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    _vm.showForm = true
                  }
                }
              })
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("emc-loader-progress", { attrs: { show: _vm.loadingProgress } }),
      _c("emc-table-items", {
        attrs: {
          headers: _vm.headers,
          params: _vm.params,
          collections: _vm.collections,
          meta: _vm.meta,
          itemDelete: _vm.itemDelete,
          itemEdit: _vm.model,
          loading: _vm.loading
        },
        on: {
          "update:params": function($event) {
            _vm.params = $event
          },
          "update:collections": function($event) {
            _vm.collections = $event
          },
          "update:meta": function($event) {
            _vm.meta = $event
          },
          "update:itemDelete": function($event) {
            _vm.itemDelete = $event
          },
          "update:item-delete": function($event) {
            _vm.itemDelete = $event
          },
          "update:itemEdit": function($event) {
            _vm.model = $event
          },
          "update:item-edit": function($event) {
            _vm.model = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "item.name",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(" " + _vm._s(item.name) + " "),
                item.description
                  ? _c("small", [_c("br"), _vm._v(_vm._s(item.description))])
                  : _vm._e()
              ]
            }
          },
          {
            key: "item.area",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(" " + _vm._s(item.bigArea.name) + " "),
                _c("br"),
                _c("small", [_vm._v(_vm._s(item.area.name))])
              ]
            }
          },
          {
            key: "item.companies",
            fn: function(ref) {
              var item = ref.item
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      item.companies
                        .map(function(c) {
                          return c.name
                        })
                        .join(", ")
                    ) +
                    " "
                )
              ]
            }
          },
          {
            key: "item.campus",
            fn: function(ref) {
              var item = ref.item
              return [
                item.campus
                  ? _c("span", [_vm._v(" " + _vm._s(item.campus.name) + " ")])
                  : _vm._e(),
                item.brand
                  ? _c("small", [_c("br"), _vm._v(_vm._s(item.brand.name))])
                  : _vm._e()
              ]
            }
          }
        ])
      }),
      _c("sys-grid-discipline-portfolio-register", {
        attrs: {
          icon: _vm.$route.meta.icon,
          model: _vm.model,
          show: _vm.showForm
        },
        on: {
          onCompleted: function($event) {
            return _vm.getData()
          },
          "update:show": function($event) {
            _vm.showForm = $event
          }
        }
      }),
      _c("emc-alert-modal", {
        attrs: { text: _vm.textDelete, disabled: _vm.deleting, show: _vm.show },
        on: {
          "update:show": function($event) {
            _vm.show = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "error",
                      loading: _vm.deleting,
                      disabled: _vm.deleting
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.deleteItem()
                      }
                    }
                  },
                  [
                    _vm._v("Excluir "),
                    _c("v-icon", { attrs: { right: "", dark: "" } }, [
                      _vm._v("mdi-delete")
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("emc-alert-snackbar", {
        attrs: {
          show: !!_vm.message.length,
          message: _vm.message,
          color: _vm.error ? "error" : "success"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }